var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"refresh_easymonitor edit_server_easymonitor",staticStyle:{"padding-top":"59px"}},[_c('v-row',[_c('v-col',{staticClass:"mainCont",staticStyle:{"min-width":"100vw","position":"fixed","left":"0","padding-left":"0px","z-index":"3"}},[_c('div',{staticClass:"mainTitle",staticStyle:{"background-color":"white","color":"black","font-weight":"bold","padding":"4px","font-size":"10px","min-width":"100vw","border-color":"#fa0025"}},[_vm._v(_vm._s(_vm.titolo))])])],1),_c('v-form',{ref:"form",attrs:{"lazy-validation":""},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"v-input v-input--is-label-active v-input--is-dirty theme--light v-text-field v-text-field--is-booted",attrs:{"id":"d_port_ws"}},[_c('div',{staticClass:"v-input__control"},[_c('div',{staticClass:"v-input__slot"},[_c('div',{staticClass:"v-text-field__slot"},[_c('label',{staticClass:"v-label v-label--active theme--light",staticStyle:{"left":"0px","right":"auto","position":"absolute"},attrs:{"for":"input-29"}},[_vm._v("Seconds")]),_c('vue-autonumeric',{attrs:{"id":"fldSecondsWaiting","options":{
                                                    digitGroupSeparator: '',
                                                    decimalCharacter: ',',
                                                    decimalCharacterAlternative: '',
                                                    currencySymbol: '',
                                                    currencySymbolPlacement: 's',
                                                    roundingMethod: 'U',
                                                    minimumValue: '0',
                                                    decimalPlaces: '0',
                                                    modifyValueOnWheel: false,
                                                }},model:{value:(_vm.refresh.seconds_waiting),callback:function ($$v) {_vm.$set(_vm.refresh, "seconds_waiting", $$v)},expression:"refresh.seconds_waiting"}})],1)]),_c('div',{staticClass:"v-text-field__details"},[_c('div',{staticClass:"v-messages theme--light"},[_c('div',{staticClass:"v-messages__wrapper"})])])])])])],1)],1),_c('div',{staticClass:"contaziende"},[[_c('div',{staticClass:"text-center"},[_c('v-bottom-sheet',{staticClass:"vaziendesheet",model:{value:(_vm.sheetAziende),callback:function ($$v) {_vm.sheetAziende=$$v},expression:"sheetAziende"}},[_c('v-sheet',{staticClass:"text-center",attrs:{"height":"100vh"}},[_c('div',{staticClass:"headerSheetAziende",staticStyle:{"position":"fixed"},attrs:{"fixed":""}},[_vm._v(" Companies "),_c('v-btn',{staticClass:"closeSheet align_close_sheet",attrs:{"icon":""},on:{"click":function($event){_vm.sheetAziende = !_vm.sheetAziende}}},[_c('v-img',{staticStyle:{"margin-right":"10px"},attrs:{"alt":"","src":require("@/assets/btn_cancel3_easymonitor.png"),"max-width":"24","max-height":"24"}})],1),_c('v-toolbar',[_c('v-text-field',{attrs:{"hide-details":"","prepend-icon":"mdi-magnify","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-divider'),_c('div',{staticClass:"spacer",staticStyle:{"min-height":"72px"}}),_c('v-flex',{attrs:{"xs12":"","sm12":""}},[_c('v-card',_vm._l((_vm.filteredAziende),function(item){return _c('v-list-item',{key:item.companyid,staticClass:"text-left",on:{"click":function($event){return _vm.manageClickAziende(item)}}},[_c('v-img',{attrs:{"alt":"","src":require("@/assets/aziende-easymonitor.png"),"max-width":"24","max-height":"24"}}),_c('v-list-item-title',{staticClass:"itemAzienda"},[_vm._v(_vm._s(item.fld_name))])],1)}),1)],1)],1)],1)],1)]],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }