<template>
    <div>

        <template>
            <div class="text-center">
                <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
                    <v-sheet class="text-center" height="100vh">
                        <div class="headerSheetAziende" fixed style="position:fixed;">

                        </div>
                        <v-divider></v-divider>
                        <div class="spacer" style="min-height:72px;"></div>
                        <v-flex xs12 sm12>
                            <v-card>

                                <!-- START -->
                                <div class="sidemenu__sub-items-container" id="menu-12">


                                    
                                    <div class="sidemenu__group-container">
                                        <div v-if="isPwdChangedOnce > 0 && isSU == 1"
                                            class="group__text" style="border-color: #fa0025;">
                                            <div style="margin-left: 5px;">Settings</div>
                                        </div>
                                        <ul class="group__items">


                                            <a v-if="isSU == 1" @click="goto('/confRefresh')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/conf_refresh_easymonitor.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Refresh</span>
                                            </a>


                                        </ul>



                                    </div>
                                   

                                    <div class="sidemenu__group-container">
                                        <div v-if="isPwdChangedOnce > 0 && isSU == 1"
                                            class="group__text" style="border-color: #fa0025;">
                                            <div style="margin-left: 5px;">Server</div>
                                        </div>
                                        <ul class="group__items">


                                            <a v-if="isPwdChangedOnce > 0 && isSU == 1" @click="goto('/server')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/server_easymonitor.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Names</span>
                                            </a>

                                            <a v-if="isPwdChangedOnce > 0 && isSU == 1" @click="goto('/checkServer')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/check_server_easymonitor.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Check</span>
                                            </a>


                                            <a v-if="isPwdChangedOnce > 0 && isSU == 1" @click="goto('/initMonitoringServer')"
                                                mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain
                                                    src="@/assets/monitoring_server_easymonitor.png" max-width="32" max-height="32"
                                                    transition="scale-transition" />
                                                <span class="subitem__text">Monitoring</span>
                                            </a>

                                        </ul>



                                    </div>







                                    <div class="sidemenu__group-container">
                                        <div class="group__text" style="border-color: #fa0025;">
                                            <div style="margin-left: 5px;">Applicazione</div>
                                        </div>
                                        <ul class="group__items">

                                            <a v-if="isPwdChangedOnce > 0" @click="goto('/dash')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">

                                                <v-img alt="Vuetify Logo" class="  " contain src="@/assets/home.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />


                                                <span class="subitem__text">Home</span>
                                            </a>

                                            <a v-if="isPwdChangedOnce > 0" @click="goto('/info')" mid="mnu_gest_company"
                                                class="sidemenu__subitem" href="#">
                                                <v-img alt="Vuetify Logo" class="  " contain src="@/assets/info2.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Info</span>
                                            </a>


                                            <a @click="logout" mid="mnu_gest_company" class="sidemenu__subitem" href="#">
                                                <v-img alt="Logout" class="  " contain src="@/assets/exit.png"
                                                    max-width="32" max-height="32" transition="scale-transition" />
                                                <span class="subitem__text">Esci</span>
                                            </a>

                                        </ul>
                                    </div>
                                </div>

                                <!-- END -->

                            </v-card>
                        </v-flex>
                    </v-sheet>
                </v-bottom-sheet>
            </div>
        </template>

    </div>
</template>

<script>
//import $ from 'jquery';
import _ from 'lodash';

export default {
    name: "menubottom",
    props: {},

    mounted: function () {

        this.setGlobal();

        var a = window.$cookies.get("a");
        this.isPwdChangedOnce = window.$cookies.get("is_pwd_changed_once");
        this.isAgila = window.$cookies.get("is_agila");
        this.isMsgUser = window.$cookies.get("is_msg_user");

        if (a == 'Y' || a == 'S') {
            this.isAdmin = 1;
            this.isSU = 1;
        }


        if (this.isMsgUser == 0) {

            if (this.isSU == 0) {

                this.viewNotifiche = this.getEnableConf("mng_not_alert");
                this.viewLogoFirma = this.getEnableConf("logo_firma_alert");
                this.viewModInvio = this.getEnableConf("mod_invio_alert");
                this.viewAziende = this.getEnableConf("azi_emt_alert");
                this.viewAziendeAgila = this.getEnableConf("azi_agila_alert");
                this.viewReferenti = this.getEnableConf("refs_alert");
                this.viewProfilo = this.getEnableConf("profilo_alert");
                this.viewUtenti = this.getEnableConf("users_alert");
                this.viewMsgBase = this.getEnableConf("msg_base_alert");
                this.viewMsgRisorse = this.getEnableConf("msg_ris_alert");
                this.viewStoricoBase = this.getEnableConf("sto_base_alert");
                this.viewMsgRefs = this.getEnableConf("msg_refs_alert");
                this.viewStoricoRefs = this.getEnableConf("sto_refs_alert");
                this.viewDocCond = this.getEnableConf("doc_cond_alertmsg");


            }
            else {

                this.viewNotifiche = 1;
                this.viewLogoFirma = 1;
                this.viewModInvio = 1;
                this.viewAziende = 1;
                this.viewAziendeAgila = 1;
                this.viewReferenti = 1;
                this.viewProfilo = 1;
                this.viewUtenti = 1;
                this.viewMsgBase = 1;
                this.viewMsgRisorse = 1;
                this.viewStoricoBase = 1;
                this.viewMsgRefs = 1;
                this.viewStoricoRefs = 1;
                this.viewDocCond = 1;

            }

        }





        if (this.isMsgUser == 1) {


            this.viewNotifiche = 0;
            this.viewLogoFirma = 0;
            this.viewModInvio = 0;
            this.viewAziende = 0;
            this.viewAziendeAgila = 0;
            this.viewReferenti = 0;
            this.viewProfilo = 1;
            this.viewUtenti = 0;
            this.viewMsgBase = 0;
            this.viewMsgRisorse = 0;
            this.viewStoricoBase = 1;
            this.viewMsgRefs = 0;
            this.viewStoricoRefs = 0;
            this.viewDocCond = 0;


        }


    },
    data() {

        return {

            viewNotifiche: 0,
            viewLogoFirma: 0,
            viewModInvio: 0,
            viewAziende: 0,
            viewAziendeAgila: 0,
            viewReferenti: 0,
            viewProfilo: 0,
            viewUtenti: 0,
            viewMsgBase: 0,
            viewMsgRisorse: 0,
            viewStoricoBase: 0,
            viewMsgRefs: 0,
            viewStoricoRefs: 0,
            viewDocCond: 0,




            isSU: 0,
            isMsgUser: 0,

            isAgila: 0,
            isAdmin: 0,
            isPwdChangedOnce: 0,
            sheetAziende: false,
            currentFile: null,
            selecting: 0,
            currItem: {},
            currGruppo: {},
            switch1: true,
            nomeCorrente: "",
            titolo: "",
            body: "",
            aziende: [],
            gruppi: [],
            globalArrAziende: [],
            dipendenti: [],
            search: '',
            sheetGruppi: false,
            sheetEmpty: false,
            sheetDipendenti: false,
            searchDipendenti: '',
            searchGruppi: '',

        };
    },

    computed: {

        filteredGruppi() {
            return _.orderBy(this.gruppi.filter(item => {
                if (!this.searchGruppi) return this.gruppi;
                // console.log ( "item", item );
                return (item.nome.toLowerCase().includes(this.searchGruppi.toLowerCase()));
            }), 'headline')
        },

        filteredDipendenti() {
            return _.orderBy(this.dipendenti.filter(item => {
                if (!this.searchDipendenti) return this.dipendenti;
                return (item.fld_name.toLowerCase().includes(this.searchDipendenti.toLowerCase()));
            }), 'headline')
        },

        filteredAziende() {
            return _.orderBy(this.aziende.filter(item => {
                if (!this.search) return this.aziende;
                return (item.toLowerCase().includes(this.search.toLowerCase()));
            }), 'headline')
        },

    },
    created() {
        this.intervalHandle = setInterval(this.tick, 1000);
    },
    beforeDestroy() {
        clearInterval(this.intervalHandle);
    },
    methods: {

        getEnableConf: function (v_codice) {

            var result = 0;

            var v_product_permissions = window.$cookies.get("product_permissions");

            var elements = v_product_permissions.split("|");

            for (var x = 0; x <= elements.length - 1; x++) {

                if (elements[x] == v_codice) {

                    result = 1;

                }


            }


            return result;

        },




        logout: function () {
            this.sheetAziende = false;
            this.$root.$children[0].logout();
        },
        goto: function (link) {

            this.sheetAziende = false;

            console.log("LINK: ", link);

            if ((link == "/profile") || (link == "/messages")) {

                this.$root.$children[0].bnavbuttons = this.$root.$children[0].old_bnavbuttons;
            }

            // var last_menu = this.$cookies.get("last_menu");

            // if ((last_menu == "/profile") && (link == "/profile")) {

            //     var pulsantis = [{
            //         text: "Save",
            //         icon: "mdi-content-save",
            //         link: "/timesheet",
            //         id: "btn_add_tms",
            //         disabled: false,
            //     },


            //     ];

            //     this.$root.$children[0].bnavbuttons = pulsantis;

            // }

            // if ((last_menu == null) && (link == "/profile")) {

            //     this.$cookies.set("last_menu", "/profile");

            // }





            // if ((last_menu == "/messages") && (link == "/messages")) {


            //     this.$root.$children[0].bnavbuttons = this.$root.$children[0].old_bnavbuttons;


            // }

            // if ((last_menu == null) && (link == "/messages")) {

            //     this.$cookies.set("last_menu", "/messages");

            // }










            return this.$router.push(link);

        },
        setGlobal: function () {

            this.aziende = this.$root.$children[0].aziende;
            this.dipendenti = this.$root.$children[0].dipendenti;
            this.gruppi = this.$root.$children[0].gruppi;
            this.globalArrAziende = this.$root.$children[0].globalArrAziende;

        },

        openmenu: function () {

            this.setGlobal();

            this.sheetAziende = true;
        },
        manageClose: function () {

            // this.setupButtons();
            this.sheetAziende = false;
            // this.sheetEmpty = false;
            this.sheetDipendenti = false;
            // this.sheetGruppi = false;


            this.$root.$children[0].bnavbuttons = this.$root.$children[0].old_bnavbuttons;


        },

    }
};
</script>

<style scoped>
/****** MENU ******/
.menu-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 2;
}

.menu__item .title,
.menu-icon .title {
    display: block;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    text-align: center;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 600;
    letter-spacing: .05em;
}

.menu__settings-icon {
    height: 26px !important;
    width: 26px !important;
    margin-bottom: 4px !important;
    margin-top: 2px !important;
}

#sidemenu {

    z-index: 2;
    font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', sans-serif;
    position: fixed;
    top: 54px;
    left: 0;
    background: transparent;
    bottom: 56px;
    padding: 28px 0;
    -webkit-transition: -webkit-transform .2s ease-in-out;
    transition: -webkit-transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out, -webkit-transform .2s ease-in-out;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

#sidemenu.active {
    -webkit-transform: translateX(0);
    -ms-transform: translateX(0);
    transform: translateX(0);
}

#sidemenu .sidemenu__inner-wrapper {
    overflow: hidden;
    background: white;
    height: 100%;
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
}

.sidemenu__top-level-item {
    width: 83px;
    text-align: center;
    border-bottom: 1px solid #efefef;
    border-right: 1px solid #efefef;
    opacity: 1;
}

sidemenu__top-level-item a {
    width: 80px;
}

.sidemenu__top-level-item>a {
    height: 80px;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-decoration: none;
    padding: 8px;
    -webkit-transition: -webkit-transform .2s ease;
    transition: -webkit-transform .2s ease;
    -o-transition: transform .2s ease;
    transition: transform .2s ease;
    transition: transform .2s ease, -webkit-transform .2s ease;
}

.sidemenu__top-level-item>a:before {
    position: absolute;
    top: 0;
    left: -3px;
    height: 100%;
    width: 3px;
    z-index: 1;
    content: '';
    display: block;
}

.sidemenu__top-level-item>a:hover {
    -webkit-transform: translateX(3px);
    -ms-transform: translateX(3px);
    transform: translateX(3px);
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    opacity: .7;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    display: block;
    margin-bottom: 4px;
    width: 30px;
}

.sidemenu__top-level-item:hover {
    background-color: #f9f9f9;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__icon {
    -webkit-filter: none;
    filter: none;
}

.sidemenu__top-level-item:hover .sidemenu__top-level-item__text {
    opacity: 1;
}

/*
  .sidemenu__sub-items-container {
    z-index: -1;
    position: absolute;
    background: white;
    overflow-y: auto;
    top: 28px;
    left: 100%;

    width: 359px;
    bottom: 28px;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    border: 1px solid #efefef;

    text-align: left;

  }*/
.sidemenu__sub-items-container .group__items {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-left: -1px;
    position: relative;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem,
.sidemenu__top-level-item>a {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-line-pack: center;
    align-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 8px;
    width: 84px
        /* 84px */
    ;
    height: 80px;
    position: relative;
    margin-right: -1px;
    margin-bottom: -1px;
    cursor: pointer;
}

.sidemenu__subitem {
    width: 120px !important;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem:hover {
    background: #f9f9f9;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem img,
.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    width: 28px;
    margin-bottom: 20px;
}

.sidemenu__top-level-item .sidemenu__top-level-item__icon {
    margin-bottom: 20px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem .subitem__text,
.sidemenu__top-level-item .sidemenu__top-level-item__text {
    position: absolute;
    top: 48px;
    max-width: 80px;
    font-size: 9.5px;
    line-height: 1.05em;
    font-weight: 500;
    color: #404040;
}

.sidemenu__top-level-item .sidemenu__top-level-item__text {
    top: 50px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    line-height: 1.1em;
    font-weight: 600;
    font-size: 9px;
}

.sidemenu__sub-items-container .group__text {
    background-color: #f9f9f9;
    font-weight: 700;
    padding: 20px 16px 6px 16px;
    padding: 1px;

    font-size: 12px;
    color: rgba(0, 0, 0, 0.78);
    letter-spacing: 0px;
    border-bottom: 2px solid #f3f3f3;
}

.sidemenu__sub-items-container.hidden {
    opacity: 0;
    -webkit-transform: translateX(-100%);
    -ms-transform: translateX(-100%);
    transform: translateX(-100%);
}

.sidemenu__sub-items-container {
    z-index: 1;

    position: absolute;
    position: fixed;
    background: white;
    overflow-y: auto;
    width: 100vw;
    bottom: 0px;
    /*border: 1px black solid;*/
    height: 100vh;
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -o-transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    transition: all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97), all 0.15s cubic-bezier(0.07, 0.77, 0.69, 0.97);
    -webkit-box-shadow: 0px 2px 5px #d2d2d2;
    box-shadow: 0px 2px 5px #d2d2d2;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-items: flex-start;
    align-content: inherit;

    justify-content: flex-end;
    bottom: 56px;

}

.sidemenu__group-container ul {
    padding-left: 0px;
}

.sidemenu__sub-items-container .group__items .sidemenu__subitem {
    border: 1px solid #efefef;
}

.sidemenu__sub-items-container img {
    max-width: 26px;
}

.v-image__image.v-image__image--contain {
    transform: translateY(-6px);
}

.sidemenu__group-container .v-image.v-responsive.theme--light {
    margin-bottom: 22px;
}
</style>
