<template>
  <v-container fluid fill-height class="text-center d-flex align-center justify-center editProfile"
    style="min-width: 280px">
    <v-form style="min-width: 300px" ref="form" v-model="valid" lazy-validation>
      <v-avatar color="primary" size="56">
        <span class="white--text text-h5">{{ initials }}</span>
      </v-avatar>
      <v-row class="text-center titlenewrow" style="">
        <span>{{ namesurname }}</span>
      </v-row>


      <v-text-field v-model="profile.name" label="Nome" required :rules="genRules"></v-text-field>

      <v-text-field v-model="profile.surname" label="Cognome" required :rules="genRules"></v-text-field>

      <v-text-field v-model="profile.mail" :rules="emailRules" label="E-mail" required></v-text-field>

      <div class="padding" style="min-height: 100px"></div>
    </v-form>

    <template>
      <div class="text-center">
        <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
          <v-sheet class="text-center" height="100vh">
            <div class="headerSheetAziende" fixed style="position: fixed">
              Aziende
              <v-btn class="closeSheet" icon @click="manageClose()">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
              <v-toolbar>
                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search">
                </v-text-field>
              </v-toolbar>
            </div>
            <!--  <v-btn class="mt-2" text color="yellow" @click="sheetAziende = !sheetAziende">
                        close
                    </v-btn> -->
            <v-divider></v-divider>
            <div class="spacer" style="min-height: 72px"></div>
            <v-flex xs12 sm12>
              <v-card>
                <v-list-item class="text-left" v-for="(tile, key) in filteredAziende" :key="key"
                  @click="manageClickAziende(tile)">
                  <v-icon aria-hidden="false">mdi-domain</v-icon>
                  <v-list-item-title class="itemAzienda">{{
                    tile
                  }}</v-list-item-title>
                  <v-icon class="iconPlusList" aria-hidden="false">mdi-plus-circle-outline</v-icon>
                </v-list-item>
              </v-card>
            </v-flex>
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </template>

    <template>
      <div class="text-center">
        <v-bottom-sheet v-model="sheetDipendenti">
          <v-sheet class="text-center" height="100vh">
            <div class="headerSheetAziende" fixed style="position: fixed">
              Dipendenti
              <!-- <v-switch @change="changeGruppi" style="position: absolute;z-index: 1;left: 8px;top: -13px;" v-model="switch1" :label="` `"></v-switch> -->

              <v-btn class="closeSheet" icon @click="manageClose()">
                <v-icon>mdi-close-circle-outline</v-icon>
              </v-btn>
              <v-toolbar>
                <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="searchDipendenti">
                </v-text-field>
              </v-toolbar>
            </div>
            <v-divider></v-divider>
            <div class="spacer" style="min-height: 72px"></div>
            <v-flex xs12 sm12>
              <v-card>
                <v-list-item class="text-left" v-for="(objDip, key) in filteredDipendenti" :key="key"
                  @click="manageClickDipendenti(objDip, 0)">
                  <v-icon aria-hidden="false">mdi-account</v-icon>
                  <v-list-item-title class="itemAzienda">{{
                    objDip.fld_name
                  }}</v-list-item-title>
                  <v-icon class="iconPlusList" aria-hidden="false">mdi-plus-circle-outline</v-icon>
                </v-list-item>
              </v-card>
            </v-flex>
          </v-sheet>
        </v-bottom-sheet>
      </div>
    </template>
  </v-container>
</template>

<script>
import apiprofile from "./utils/profile/apiprofile";
import apitrial from "./utils/trial/apitrial";

/* import {
    bus
} from "../main"; */
/* import router from ".././router"; */
//import $ from 'jquery';
import _ from "lodash";
import $ from "jquery";
export default {
  computed: {
    filteredGruppi() {
      return _.orderBy(
        this.gruppi.filter((item) => {
          if (!this.searchGruppi) return this.gruppi;
          // console.log ( "item", item );
          return item.nome
            .toLowerCase()
            .includes(this.searchGruppi.toLowerCase());
        }),
        "headline"
      );
    },

    filteredDipendenti() {
      return _.orderBy(
        this.dipendenti.filter((item) => {
          if (!this.searchDipendenti) return this.dipendenti;
          return item.fld_name
            .toLowerCase()
            .includes(this.searchDipendenti.toLowerCase());
        }),
        "headline"
      );
    },

    filteredAziende() {
      return _.orderBy(
        this.aziende.filter((item) => {
          if (!this.search) return this.aziende;
          return item.toLowerCase().includes(this.search.toLowerCase());
        }),
        "headline"
      );
    },
  },

  destroyed() {
    this.$root.$children[0].bnavbuttons = [];

    window.App.$off("bottomIconClickEvent");
    //window.removeEventListener("scroll", this.onScroll, false);

    //document.querySelector('#btn_add_tms').removeEventListener('click' , this.btn_add_tms_click);
  },

  mounted: function () {
    /*     let self = this;
            fetch('https://jsonplaceholder.typicode.com/posts')
              .then(response => response.json())
              .then(json => self.$data.items = json) */

    //var res = apiprofile.getProfile(1,2);

    // this.$root.$children[0].totalMsgBaseRis = 0;
    // this.$root.$children[0].totalMsgRefs = 0;


    // this.$root.$children[0].normalDiv1 = true;
    // this.$root.$children[0].normalDiv2 = true;

    // this.$root.$children[0].invoiceDiv1 = false;
    // this.$root.$children[0].invoiceDiv2 = false;
    // this.$root.$children[0].invoiceDiv3 = false;
    // this.$root.$children[0].invoiceDiv4 = false;

    // this.$root.$children[0].curTitle = "Profilo";


    if (window.context == "alertmsg") {



      this.verifyTrialPeriod();


    }







    if (window.context == "invoiceweb") {


      this.$root.$children[0].normalDiv1 = true;
      this.$root.$children[0].normalDiv2 = true;

      this.$root.$children[0].invoiceDiv1 = false;
      this.$root.$children[0].invoiceDiv2 = false;
      this.$root.$children[0].invoiceDiv3 = false;
      this.$root.$children[0].invoiceDiv4 = false;
      this.$root.$children[0].invoiceDiv3Agila = false;

      this.$root.$children[0].calcolatorDiv1 = false;

      var namesurname = window.$cookies.get("namesurname");

      this.$root.$children[0].curTitle = namesurname;
      this.$root.$children[0].invoiceTitle = "Profilo";



    }




    this.dipendenti = this.$root.$children[0].dipendenti;

    this.gruppi = this.$root.$children[0].gruppi;

    this.aziende = this.$root.$children[0].aziende;
    this.globalArrAziende = this.$root.$children[0].globalArrAziende;

    var that = this;

    this.setupButtons();

    if (window.context != "alertmsg") {

      setTimeout(() => {
        this.syncProfile();
      }, 100);


    }

    // console.log (res);

    //var bus = window.App;

    //bus.$on("bottomIconClickEvent", (data) => {


    $("body").on("click", ".v-bottom-navigation button", function (event) {
      console.log(event);
      var data = $(this).find("img").attr("id");



      //this.header = data;
      console.log(data);
      switch (data) {
        case "btn_aziende":
          //this.admindialog = true ;
          this.$root.$children[0].showProgress = true;

          setTimeout(() => {
            this.sheetAziende = true;
            this.$root.$children[0].showProgress = false;
          }, 300);
          break;

        case "btn_delete_profile":

          that.deleteAccountAction();

          break;

        case "btn_save_profile":

          that.validate();

          break;

        case "btn_exit":
          //alert("btn_add_x");
          this.$router.push({
            path: "/dash",
          });
          break;

        default:
          break;
      }
    });

    this.setupButtons();
  },
  data: () => ({
    currDipendenteObj: {},
    aziende: [],
    dipendenti: [],
    currAzienda: "",

    sheetAziende: false,
    sheetDipendenti: false,
    searchDipendenti: "",
    search: "",

    showPicker: false,
    profile: {},
    valid: true,
    initials: "",
    namesurname: "",
    iban: "",
    cellulare: "",
    codicefiscale: "",
    codicefiscaleRules: [
      (v) => !!v || "Codice fiscale is required",
      (v) =>
        (v && v.length <= 16) ||
        "Codice fiscale must be less than 16 characters",
    ],
    cellulareRules: [
      (v) => !!v || "Phone number is required",
      (v) =>
        (v && v.length <= 30) || "Phone number must be less than 15 characters",
    ],
    genRules: [(v) => !!v || "Field required"],
    ibanRules: [
      (v) => !!v || "Iban is required",
      (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
    ],
    email: "",
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    select: null,
    items: ["Item 1", "Item 2", "Item 3", "Item 4"],
    checkbox: false,
  }),

  methods: {

    verifyTrialPeriod: async function () {

      var that = this;

      var v_token = window.$cookies.get("token");

      that.showSpinner = true;
      that.$root.$children[0].showProgress = true;

      var response = await apitrial.verifyTrial(
        v_token,
        "Alertmsg"
      ).then((res) => {

        that.$root.$children[0].showProgress = false;
        console.log("res from verifyTrial", res);

        if (res.data.is_enable == "N") {



          that.$root.$children[0].logout();


        }
        else {



          setTimeout(() => {
            that.syncProfile();
          }, 100);



        }




      }).catch(err => {
        that.$root.$children[0].showProgress = false;
        console.log(err);
        var msg = err.response.data.Error;

        that.$swal({
          icon: "error",
          text: msg,
          showConfirmButton: false,
          timer: 8000
        });
        console.log("Errori", "Non è stato possibile eliminare i dati");
        console.log("response", response);

      }

      );



    },





    deleteAccountAction: function () {

      var that = this;

      console.log("PROFILE: ", that.profile);


      // this.showSpinner = true;

      //  var site_domain = "https://app.emtool.eu/";

      var spinner = $("#bspinner");
      var loginButton = $("#login-button");

      var API_URL = this.getUrlDomain("api/resetAccount");

      //alert (window.context);


      var loginpar = window.$cookies.get("username");

      var imgConfBtn = "<img title='Conferma' style='width:25px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3.png' />";
      var imgRemBtn = "<img title='Annulla' style='width:25px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3.png' />";

      if (window.context == "2recruit") {

        imgConfBtn = "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_2recruit.png' />";
        imgRemBtn = "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_2recruit.png' />";

      }

      if (window.context == "invoiceweb") {

        imgConfBtn = "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_invoiceweb.png' />";
        imgRemBtn = "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_invoiceweb.png' />";

      }

      if (window.context == "alertmsg") {

        imgConfBtn = "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_alertmsg.png' />";
        imgRemBtn = "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_alertmsg.png' />";

      }

      if (window.context == "emt") {

        imgConfBtn = "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_emt.png' />";
        imgRemBtn = "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_emt.png' />";

      }

      if (window.context == "prjweb") {

        imgConfBtn = "<img title='Conferma' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3_prjweb.png' />";
        imgRemBtn = "<img title='Annulla' style='width:30px' src='https://app.emtool.eu/public/_lib/img/btn_cancel3_prjweb.png' />";

      }

      //  var that = this;
      this.$swal.fire({
        title: "Account remove",
        html: "Do you want to remove your account ?",
        showCancelButton: true,
        confirmButtonText: imgConfBtn,
        cancelButtonText: imgRemBtn,
        customClass: {
          cancelButton: 'order-1',
          confirmButton: 'order-2',
        },

        /*  input: "text", */

        inputAttributes: {
          autocapitalize: "off",
        },

        preConfirm: (username) => {
          console.log(username);

          // show spinner && disable button
          spinner.removeClass("vhidden");
          that.showSpinner = true;

          loginButton.prop("disabled", true);

          var body = {
            loginpar: loginpar,
            email: that.profile.mail,
            method: "REMOVE_ACCOUNT",
            context: window.context,
          };

          console.log(body);
          // call the service

          that.showSpinner = true;
          that.$root.$children[0].showProgress = true;


          $.ajax({
            type: "POST",
            url: API_URL,
            data: body,
            success: function (resultData) {

              that.showSpinner = false;
              that.$root.$children[0].showProgress = false;

              // hide spinner && enable button
              spinner.addClass("vhidden");
              that.showSpinner = false;

              loginButton.prop("disabled", false);

              if (resultData.Result == "OK") {
                var message;

                if (resultData.send_type == "SMS") {
                  message = "Check your Sms";
                } else {
                  message = "Check your email";
                }

                //open ok modal

                that.$swal.fire({
                  icon: "success",
                  title: message,
                  showConfirmButton: true,
                  confirmButtonText: imgConfBtn,
                });

                that.$root.$children[0].logout();

              } else {
                var errorMessage;

                switch (resultData.Result) {
                  case "USER_NOT_FOUND":
                    errorMessage = "User not found";
                    break;
                  default:
                    errorMessage = "User not found ";
                }

                that.$swal.fire({
                  title: "Errors",
                  text: errorMessage,
                  icon: "error",
                  confirmButtonText: "<img title='Conferma' style='width:25px' src='https://app.emtool.eu/public/_lib/img/btn_confirm3.png' />",
                });

                that.showSpinner = false;
              }
            },
            error: function () {
              // hide spinner && enable login button
              spinner.addClass("vhidden");
              loginButton.prop("disabled", false);
              that.showSpinner = false;
              //  errorHandler(event);
            },
          });
        },
      });
    },

    getUrlDomain: function (nome_metodo) {

      let result = "";

      if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
      }

      if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

      }

      return result + nome_metodo;

    },

    changeDip: function () {
      /*   var token = window.$cookies.get("token");
              var system = window.$cookies.get("system");
              var user_id = window.$cookies.get("user_id");

              if (this.currDipendenteObj.resourceid !== undefined) {
                  //alert (  this.currDipendenteObj.resourceid  );
                  system = this.currDipendenteObj.v_res_id;
                  token = this.currDipendenteObj.v_user;
                  user_id = this.currDipendenteObj.v_user_id;

              } */

      var selNameDip = "";
      for (const [key, dip] of Object.entries(this.dipendenti)) {
        console.log("key", key);
        if (parseInt(dip.resourceid) == this.currDipendente) {
          this.currDipendenteObj = dip;
          console.log("dip", dip);
          selNameDip = dip.fld_name;
        }
      }

      this.$root.$children[0].curDipTop = selNameDip;

      // console.log(token, system, user_id);
      this.syncProfile();
    },
    manageClickDipendenti: function (dipendente) {
      this.sheetDipendenti = false;
      this.currDipendente = dipendente.resourceid;
      //  alert ( dipendente );
      // this.resetDay();
      this.changeDip();
      setTimeout(() => {
        // this.syncMonth();
      }, 100);
      /*   this.syncArrayDipendenti();
              console.log ("CurAzienda",this.currAzienda );
              console.log(this.dipendenti);

              this.sheetDipendenti = true ; */
    },

    syncArrayDipendenti: async function () {
      console.log(this.currDipendente);

      this.currDipendente = "";
      this.$root.$children[0].curDipTop = "";

      // this.$root.$children[0].curDipOreTop = "";
      // this.$root.$children[0].curDipOreTopLabel = "";

      //aziende
      var arrAziende = [];
      var arrDipendenti = [];

      var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
      //   var aziende =   this.aziende  ;

      console.log("Elenco aziende:", this.aziende);
      // this.currAzienda
      //console.log ( "aziendeOK" , aziende );
      for (var az of aziende) {
        var currArAzienda = az[1].fld_name;
        console.log("az", az);
        arrAziende.push(az[1].fld_name);

        var dipArray = az[1].dipendenti;
        for (var dip of dipArray) {
          //console.log ( dip.fld_name );
          //  console.log ( dip );
          if (currArAzienda == this.currAzienda) {
            arrDipendenti.push(dip);
          }
        }
      }
      //this.aziende = arrAziende;
      this.dipendenti = arrDipendenti;
    },

    manageClickAziende: async function (azienda) {
      this.currAzienda = azienda;
      await this.syncArrayDipendenti();

      console.log("CurAzienda", this.currAzienda);
      console.log(this.dipendenti);

      setTimeout(() => {
        this.sheetAziende = false;
        this.sheetDipendenti = true;
      }, 300);
    },

    manageClose: function () {
      this.setupButtons();
      this.sheetAziende = false;
      this.sheetEmpty = false;
      this.sheetDipendenti = false;
      this.sheetGruppi = false;
    },

    setupButtons: async function () {
      setTimeout(() => {
        // console.log ("here we go" , this.$root.$children[0].drawer ) ;
        //window.App.$children[0].setToolbar();
        //   this.$root.$children[0].setToolbar();

        var pulsantis = [];


        if (window.context == "2recruit") {

          pulsantis.push({
            text: "Save",
            icon: "mdi-content-save",
            link: "/timesheet",
            id: "btn_add_tms",
            disabled: false,
            image: "https://app.emtool.eu/public/_lib/img/save_update_data-2recruit.png",
            title: "Salva",
            width: 28,
          });

          pulsantis.push({
            text: "",
            icon: "mdi-domain",
            link: "/dash",
            id: "btn_save",
            disabled: false,
            image: "https://app.emtool.eu/public/_lib/img/delete-2recruit.png",
            title: "Elimina",
            width: 30,
          });

        }
        else if (window.context == "invoiceweb") {

          pulsantis.push({
            text: "Salva",
            icon: "mdi-content-save",
            link: "/timesheet",
            id: "btn_save_profile",
            disabled: false,
            image: "https://app.emtool.eu/public/_lib/img/save_update_data-invoiceweb.png",
            title: "Salva",
            width: 30
          });

          pulsantis.push({
            text: "Delete",
            icon: "mdi-home-circle",
            image: "https://app.emtool.eu/public/_lib/img/delete-invoiceweb.png",
            link: "/info",
            id: "btn_delete_profile",
            disabled: false,
            title: "Elimina",
            width: 30
          });

        }
        else if (window.context == "alertmsg") {

          pulsantis.push({
            text: "Salva",
            icon: "mdi-content-save",
            link: "/timesheet",
            id: "btn_save_profile",
            disabled: false,
            image: "https://app.emtool.eu/public/_lib/img/save-alertmsg.png",
            title: "Salva",
            width: 30
          });

          pulsantis.push({
            text: "Delete",
            icon: "mdi-home-circle",
            image: "https://app.emtool.eu/public/_lib/img/delete-alertmsg.png",
            link: "/info",
            id: "btn_delete_profile",
            disabled: false,
            title: "Elimina",
            width: 30
          });

        }
        else if (window.context == "emt") {

          pulsantis.push({
            text: "Salva",
            icon: "mdi-content-save",
            link: "/timesheet",
            id: "btn_save_profile",
            disabled: false,
            image: "https://app.emtool.eu/public/_lib/img/icon_save_emt.png",
            title: "Salva",
            width: 30
          });

          pulsantis.push({
            text: "Delete",
            icon: "mdi-home-circle",
            image: "https://app.emtool.eu/public/_lib/img/icon_delete_emt.png",
            link: "/info",
            id: "btn_delete_profile",
            disabled: false,
            title: "Elimina",
            width: 30
          });

        }
        else if (window.context == "prjweb") {

          pulsantis.push({
            text: "Salva",
            icon: "mdi-content-save",
            link: "/timesheet",
            id: "btn_save_profile",
            disabled: false,
            image: "https://app.emtool.eu/public/_lib/img/save_update_data-prjweb.png",
            title: "Salva",
            width: 30
          });

          pulsantis.push({
            text: "Delete",
            icon: "mdi-home-circle",
            image: "https://app.emtool.eu/public/_lib/img/delete-prjweb.png",
            link: "/info",
            id: "btn_delete_profile",
            disabled: false,
            title: "Elimina",
            width: 30
          });

        }
        else if (window.context == "jobmule") {

          pulsantis.push({
            text: "Salva",
            icon: "mdi-content-save",
            link: "/timesheet",
            id: "btn_save_profile",
            disabled: false,
            image: "https://app.emtool.eu/public/_lib/img/save_update_data-careers.png",
            title: "Salva",
            width: 30
          });

          pulsantis.push({
            text: "Delete",
            icon: "mdi-home-circle",
            image: "https://app.emtool.eu/public/_lib/img/delete-careers.png",
            link: "/info",
            id: "btn_delete_profile",
            disabled: false,
            title: "Elimina",
            width: 30
          });

        }

        else {

          pulsantis.push({
            text: "Save",
            icon: "mdi-content-save",
            link: "/timesheet",
            id: "btn_add_tms",
            disabled: false,
            image: "https://app.emtool.eu/public/_lib/img/save_easyer2.png",
            title: "Salva",
            width: 22,
          });

          pulsantis.push({
            text: "",
            icon: "mdi-domain",
            link: "/dash",
            id: "btn_save",
            disabled: false,
            image: "https://app.emtool.eu/public/_lib/img/delete_easyonoff.png",
            title: "Elimina",
            width: 33,
          });

        }





        // if (window.context == "2recruit") {

        //   pulsantis.push({
        //     text: "Save",
        //     icon: "mdi-content-save",
        //     link: "/timesheet",
        //     id: "btn_add_tms",
        //     disabled: false,
        //     image: "https://app.emtool.eu/public/_lib/img/save_update_data-2recruit.png",
        //     title: "Salva",
        //     width: 28,
        //   });

        //   pulsantis.push({
        //     text: "",
        //     icon: "mdi-domain",
        //     link: "/dash",
        //     id: "btn_save",
        //     disabled: false,
        //     image: "https://app.emtool.eu/public/_lib/img/delete-2recruit.png",
        //     title: "Elimina",
        //     width: 30,
        //   });

        // }
        // else {

        //   pulsantis.push({
        //     text: "Save",
        //     icon: "mdi-content-save",
        //     link: "/timesheet",
        //     id: "btn_add_tms",
        //     disabled: false,
        //     image: "https://app.emtool.eu/public/_lib/img/save_easyer2.png",
        //     title: "Salva",
        //     width: 22,
        //   });

        //   pulsantis.push({
        //     text: "",
        //     icon: "mdi-domain",
        //     link: "/dash",
        //     id: "btn_save",
        //     disabled: false,
        //     image: "https://app.emtool.eu/public/_lib/img/delete_easyonoff.png",
        //     title: "Elimina",
        //     width: 33,
        //   });

        // }









        //window.App.$children[0].bnavbuttons = pulsantis;
        this.$root.$children[0].bnavbuttons = pulsantis;

        //   this.$root.$children[0].$refs.btn_add_tms;

        // console.log( "ok" ,  this.$root.$refs.mainApp );
        //this.$root.$refs.mainApp.bnavbuttons = pulsantis;

        //   refs.btn_add_tms
        // console.log ("Refs" , this.$root.$children[0].$refs.btn_add_tms );

        // var refs = this.$root.$children[0].$refs.btn_add_tms ;

        //   var add_tms = refs.btn_add_tms ;

        //d console.log(this.$root.$children[0].$refs.btn_add_tms);
        setTimeout(() => {
          //document.querySelector('#btn_add_tms').addEventListener('click',  this.btn_add_tms_click)
          //this.$root.$children[0].$refs.btn_add_tms.$el.addEventListener('click', this.onClick);
        }, 100);

        // this.$root.$children[0].$refs.btn_add_tms[0].$el.addEventListener('click', this.onClick);
      }, 100);
    },

    /*       setupButtons: async function () {
        setTimeout(() => {
            var pulsantis = [{
                text: "Dashboard",
                icon: "mdi-home-circle",
                link: "/dash",
                id: "btn_exit",
                disabled: false,
            }, ];
            var a = window.$cookies.get("a");
            if (a == 'Y' || a == 'S') {
                pulsantis.push({
                    text: "",
                    icon: "mdi-message-plus",
                    link: "/dash",
                    id: "btn_aziende",
                    disabled: false,
                }, );
            }
            this.$root.$children[0].bnavbuttons = pulsantis;
        }, 100);
    },
*/

    saveProfile: async function () {
      var username = window.$cookies.get("username");
      var token_system = window.$cookies.get("token_system");

      if (this.currDipendenteObj.resourceid !== undefined) {
        username = atob(this.currDipendenteObj.v_user);
        token_system = this.currDipendenteObj.way;
      }

      this.showSpinner = true;
      this.$root.$children[0].showProgress = true;

      var response;

      console.log(username, token_system);
      try {
        response = await apiprofile.setProfile(
          username,
          token_system,
          this.profile
        );
      } catch (error) {
        this.showSpinner = false;
        console.log(error);
      }

      this.showSpinner = false;
      this.$root.$children[0].showProgress = false;

      try {
        console.log(response.data.Result);

        this.$swal({
          icon: "success",
          text: "Dati salvati correttamente",
          showConfirmButton: false,
          timer: 2000,
        });

        /*     this.profile = this.iban = response.data.Result;
                       //console.log("HERE",response.data.Result.iban);
                    this.iban = response.data.Result.iban ;
                    this.email = response.data.Result.mail ;
                    this.codicefiscale = response.data.Result.codice_fiscale ;
                    this.cellulare = response.data.Result.tel_cell ;
                    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
                    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

        //this.monthArray = response.data.Timesheet;
      } catch (error) {
        console.log(error);
      }

      //  this.data.days = response.data ;
    },

    syncProfile: async function () {
      /*  var token = window.$cookies.get("token");
             var system = window.$cookies.get("system"); */

      setTimeout(async () => {
        var username = window.$cookies.get("username");
        var token_system = window.$cookies.get("token_system");

        console.log("oki", this.currDipendenteObj);

        if (this.currDipendenteObj.resourceid !== undefined) {
          //alert (  this.currDipendenteObj.resourceid  );
          token_system = this.currDipendenteObj.way;
          username = atob(this.currDipendenteObj.v_user);
        }

        //   console.log ( system );
        this.showSpinner = true;
        this.$root.$children[0].showProgress = true;

        var result;
        try {
          // alert ( username + " +" + token_system );
          result = await apiprofile
            .getProfile(username, token_system)
            .then((response) => {
              // alert ("finshed");

              // console.log (response);
              this.showSpinner = false;
              this.$root.$children[0].showProgress = false;

              try {
                this.profile = response.data.Result;
                this.profile.username = username;
                //console.log("HERE",response.data.Result.iban);
                this.iban = response.data.Result.iban;
                this.email = response.data.Result.mail;
                this.codicefiscale = response.data.Result.codice_fiscale;
                this.cellulare = response.data.Result.tel_cell;
                this.namesurname =
                  response.data.Result.name +
                  " " +
                  response.data.Result.surname;
                this.initials =
                  response.data.Result.name.charAt(0) +
                  "" +
                  response.data.Result.surname.charAt(0);

                //this.monthArray = response.data.Timesheet;
              } catch (error) {
                console.log(error);
                alert(error.message);
              }

              console.log(result);
            });
        } catch (error) {
          alert(error.message);

          this.showSpinner = false;
          this.$root.$children[0].showProgress = false;
        }
      }, 300);

      //  this.data.days = response.data ;
    },

    btn_add_tms_click() {
      console.log(1);
    },
    /*          onClick() {
  console.log('a phone number was clicked');
}    <template>
    <div class="text-center">
        <v-bottom-sheet v-model="sheetAziende" class="vaziendesheet">
            <v-sheet class="text-center" height="100vh">
                <div class="headerSheetAziende" fixed style="position:fixed;">
                    Aziende
                    <v-btn class='closeSheet' icon @click="manageClose()">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                    <v-toolbar>
                        <v-text-field hide-details prepend-icon="mdi-magnify" single-line v-model="search"></v-text-field>
                    </v-toolbar>
                </div>
                <!--  <v-btn class="mt-2" text color="yellow" @click="sheetAziende = !sheetAziende">
                    close
                </v-btn> -->
                <v-divider></v-divider>
                <div class="spacer" style="min-height:72px;"></div>
                <v-flex xs12 sm12>
                    <v-card>

                        <v-list-item class="text-left" v-for="(tile,key) in filteredAziende" :key="key" @click="manageClickAziende(tile)">
                            <v-icon aria-hidden="false">mdi-domain</v-icon>
                            <v-list-item-title class='itemAzienda'>{{ tile }}</v-list-item-title>
                            <v-icon class='iconPlusList' aria-hidden="false">mdi-plus-circle-outline</v-icon>
                        </v-list-item>
                    </v-card>
                </v-flex>
            </v-sheet>
        </v-bottom-sheet>
    </div>
</template>

,
*/
    reply(par) {
      console.log(par);
    },
    validate() {
      //this.$root.$children[0].showProgress = true;

      var res = this.$refs.form.validate();
      console.log(res);
      if (!res) {
        this.$swal({
          icon: "error",
          text: "Please check your data",
        });
      } else {
        console.log("Profile: ", this.profile);
        this.saveProfile();
      }
    },

    reset() {
      this.$refs.form.reset();
    },
    resetValidation() {
      this.$refs.form.resetValidation();
    },
  },
};
</script>

<style scoped>
.titlenewrow {
  justify-content: center;
  padding-top: 25px;
  padding-bottom: 30px;
  font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
  box-shadow: none;
}

.editProfile .v-input {
  font-size: 1.2em !important;
}

.editProfile .v-select {
  font-size: 1.2em !important;
}

.editProfile .v-label {
  font-size: 1em !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}



.editProfile .v-label {
  font-size: 11px !important;
  color: black !important;
  font-weight: bold !important;
}


.editProfile .v-input {
  font-size: 11px !important;
  padding: 10px !important;
  color: black !important;
  font-weight: bold !important;
}

.editProfile ion-select {
  font-size: 11px !important;
  color: black !important;
  font-weight: bold !important;
}
</style>
