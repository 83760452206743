import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIMessaggi {

  getUrlDomain(nome_metodo) {

    let result = "";

    if (location.hostname == "localhost") {
      result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

      result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async getInfoMessaggioRef(user, id) {

    let formData = new FormData();

    formData.append("id", id);
    formData.append("username", user);

    return axios.post(this.getUrlDomain("AlertMsgGetInfoMsgRef"), formData, { 'Content-Type': 'multipart/form-data' });

  }



}

export default new APIMessaggi({
  url: "https://services.ebadge.it/public/api/"
})
