<template>
    <v-container fluid class="text-center d-flex align-center justify-center cont_messages"
        style="max-width: 100%; min-width: 300px;padding-bottom:100px;">









    </v-container>
</template>

<script>
import apiprofile from "../utils/profile/apiprofile";


// import {
//     bus
// } from "../main";

import router from ".././router";

//import $ from 'jquery';
//import _ from 'lodash';

import Vue from "vue";

import VueSignaturePad from 'vue-signature-pad';

import apieasymonitor from "../utils/easymonitor/apieasymonitor";


Vue.use(VueSignaturePad);


export default {


    mounted: function () {

        /*eslint-disable no-undef*/

        this.getSecondsRefresh();


        setTimeout(() => {

            this.doCheckInTable();

        }, 200);



    },

    data: () => ({


        textSearch: "",

        definput: { name: "", surname: "", mailaddress: "", titolo: "", testo: "" },

        pdfSource: "",
        page: 1,

        currentFile: null,
        selecting: 0,
        currItem: {},
        currItemRow: {},
        currGruppo: {},
        switch1: true,
        nomeCorrente: "",
        nomeCompletoCorrente: "",
        titolo: "",
        body: "",
        mailaddress: "",
        enablesignature: false,
        aziende: [],
        gruppi: [],
        globalArrAziende: [],
        dipendenti: [],
        search: '',
        sheetGruppi: false,
        sheetSignature: false,
        sheetAziende: false,
        sheetEmpty: false,
        sheetDipendenti: false,
        searchDipendenti: '',
        searchGruppi: '',

        currAzienda: "",

        defaultItem: {
            title: 'ti',
            body: '',
            read: 0,
            accepted: 0,
            id: 0

        },
        defaultItemSend: {
            title: '',
            body: '',

        },

        dialogMsgSend: false,
        dialogMsg: false,
        dialogMsgSendDemo: false,

        singleSelect: true,

        totalDesserts: 0,
        desserts: [],
        loading: true,
        options: {},
        headers: [{
            text: ' ',
            value: 'read',
            align: 'start'
        },
        {
            text: 'Date',
            value: 'created_at',
            align: 'start'
        },
        {
            text: 'Title',

            sortable: true,
            value: 'title',
            align: 'start'
        },
        {
            text: 'Receiver',

            sortable: true,
            value: 'dest',
            align: 'start'
        },

        ],

        showPicker: false,
        profile: {},
        valid: true,
        initials: "",
        namesurname: "",
        iban: "",
        cellulare: "",
        codicefiscale: "",
        codicefiscaleRules: [
            (v) => !!v || "Codice fiscale is required",
            (v) =>
                (v && v.length <= 16) ||
                "Codice fiscale must be less than 16 characters",
        ],
        cellulareRules: [
            (v) => !!v || "Phone number is required",
            (v) =>
                (v && v.length <= 30) || "Phone number must be less than 15 characters",
        ],
        genRules: [(v) => !!v || "Field required"],
        ibanRules: [
            (v) => !!v || "Iban is required",
            (v) => (v && v.length <= 30) || "Iban must be less than 30 characters",
        ],
        email: "",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        select: null,
        items: ["Item 1", "Item 2", "Item 3", "Item 4"],
        checkbox: false,
    }),

    methods: {



        getSecondsRefresh: async function () {

            var that = this;


            that.showSpinner = true;
            that.$root.$children[0].showProgress = true;

            var response = await apieasymonitor.getRefresh(
                0
            ).then((res) => {

                that.$root.$children[0].showProgress = false;
                console.log("res from getRefresh", res);

                if (res.data.Result != null) {

               
                    window.$cookies.set("seconds_refresh", res.data.Result.seconds_waiting * 1000, "9y");


                }



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile salvare i dati");
                console.log("response", response);

            }

            );

        },

        doCheckInTable: async function () {

            var that = this;


            var response = await apieasymonitor.doCheckServerInTable(
                0
            ).then((res) => {

                console.log("res from doCheckServerInTable", res);


                setTimeout(() => {

                    router.push({
                        path: "/monitoringServer"
                    });



                }, 100);



            }).catch(err => {
                that.$root.$children[0].showProgress = false;
                console.log(err);
                var msg = err.response.data.Error;

                that.$swal({
                    icon: "error",
                    text: msg,
                    showConfirmButton: false,
                    timer: 8000
                });
                console.log("Errori", "Non è stato possibile eliminare i dati");
                console.log("response", response);

            }

            );


        },


        getUrlDomainApi: function (nome_metodo) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/api/" + nome_metodo;

        },


        getUrlAttachment: function (nome_file) {

            let result = "";

            if (location.hostname == "localhost") {
                result = location.protocol + "//emtool.local/";
            }

            if (location.hostname != "localhost") {

                result = location.protocol + "//" + location.hostname + "/";

            }

            return result + "public/documents/" + nome_file;

        },


        selectFile(file) {
            // this.progress = 0;
            this.currentFile = file;
        },



        sendMsgDemo: function () {

            var name = $("#name").val();
            var surname = $("#surname").val();
            var mailaddress = $("#mailaddress").val();
            var title = $("#titolo").val();
            var body = $("#body").val();

            //var that = this;

            var chkEnableSign = 0;
            // if ($(".checkEnableSignature").prop("checked")) {
            //     chkEnableSign = 1;
            // }


            var errore = "";
            if (name.length == 0) {
                errore = "Specificare il nome";
            }
            if (errore.length == 0) {
                if (surname.length == 0) {
                    errore = "Specificare il cognome";
                }
            }
            if (errore.length == 0) {
                if (mailaddress.length == 0) {
                    errore = "Specificare l'indirizzo mail";
                }
            }
            if (errore.length == 0) {
                if (title.length == 0) {
                    errore = "Specificare l'oggetto";
                }
            }
            if (errore.length == 0) {
                if (body.length == 0) {
                    errore = "Specificare il testo";
                }
            }

            console.log("ATTACHMENT: ", $('#input-file-logo')[0].files);

            this.currentFile = $('#input-file-logo')[0].files;

            console.log(chkEnableSign);

            if (errore.length == 0) {

                if ($('#input-file-logo')[0].files.length > 0) {

                    var myfile = $('#input-file-logo')[0].files[0];

                    var lfile = myfile.name.split(".");

                    console.log("MYFILE: ", lfile[lfile.length - 1]);

                    var extension = lfile[lfile.length - 1]

                    console.log("EXT: ", extension);

                    if (extension.toString().toUpperCase() != "PDF") {

                        errore = "Accettati solo file pdf";

                    }


                }

            }



            if (errore.length == 0) {

                if ($('#input-file-logo')[0].files.length > 0) {

                    var myfileSize = $('#input-file-logo')[0].files[0];

                    if (myfileSize.size > 2000000) {

                        errore = "Grandezza massima allegato 2 MB";

                    }


                }

            }






            if (errore.length == 0) { // se la firma è abilitata controllo che sia stato specificato l'allegato

                if (chkEnableSign == 1) {

                    if ($('#input-file-logo')[0].files.length == 0) {


                        errore = "Specificare l'allegato";


                    }


                }

            }


            var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");

            var response;


            if (errore.length == 0) {

                this.showSpinner = true;

                this.nomeCorrente = mailaddress;

                try {
                    response = this.$api.insertMessageDemo(
                        this.nomeCorrente,
                        token,
                        username,
                        title,
                        body,
                        0,
                        this.currentFile,
                        mailaddress,
                        name,
                        surname,
                        chkEnableSign
                    );

                    this.$swal({
                        icon: "success",
                        text: "Il tuo messaggio è stato inviato correttamente",
                        showConfirmButton: false,
                        timer: 3000
                    });



                    setTimeout(() => {


                        router.push({
                            path: "/messages"
                        });


                    }, 3000);







                } catch (error) {
                    this.showSpinner = false;
                    console.log(error);
                }

            }

            if (errore.length > 0) {

                this.$swal({
                    icon: "error",
                    text: errore
                });

            }



            console.log(response);

        },


        sendMsg: function () {

            var title = $("#titolo").val();
            var body = $("#body").val();

            console.log("ATTACHMENT: ", $('#input-file-logo')[0].files);

            this.currentFile = $('#input-file-logo')[0].files;


            var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");
            var response;

            console.log(" this.currGruppo", this.currGruppo.nome);
            var isgroup = 0;
            if (this.currGruppo.nome !== undefined) {
                isgroup = 1;

            }
            if (title.length > 0) {
                try {
                    response = this.$api.insertMessage(
                        this.nomeCorrente,
                        token,
                        username,
                        title,
                        body,
                        isgroup,
                        this.currentFile

                    );

                    this.$swal({
                        icon: "success",
                        text: "Your message has been sent correctly",
                        showConfirmButton: false,
                        timer: 2000
                    });

                    setTimeout(() => {

                        this.closeSendMsg();

                        //this.getDataFromApi();
                    }, 2000);

                    // setTimeout(() => {
                    //     window.table.ajax.reload();

                    // }, 100);

                } catch (error) {
                    this.showSpinner = false;
                    console.log(error);
                }
            } else {
                this.$swal({
                    icon: "error",
                    text: "Please insert the title and body message"
                });

            }

            console.log(response);

        },



        changeDip: function (selectObj) {
            console.log(selectObj);
            var selNameDip = "";
            for (const [key, dip] of Object.entries(this.dipendenti)) {
                console.log("key", key);
                if (parseInt(dip.resourceid) == this.currDipendente) {
                    this.currDipendenteObj = dip;
                    console.log("dip", dip);
                    selNameDip = dip.fld_name;
                    //set display
                    this.nomeCorrente = atob(dip.v_user);

                    this.nomeCompletoCorrente = dip.fld_name;

                }
            }
            console.log(selNameDip);
            //  this.$root.$children[0].curDipTop = selNameDip;

        },

        initMsgSendDemo: function () {

            console.log("MANAGE_CLICK_DIPENDENTI");

            //   console.log(isGroup);
            this.sheetDipendenti = false;
            this.sheetGruppi = false;
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.dialogMsgSendDemo = true;


            this.definput.name = "";
            this.definput.surname = "";
            this.definput.mailaddress = "";
            this.definput.titolo = "";
            this.definput.testo = "";

            $(".checkEnableSignature").prop("checked", false);

            $('.dropify-clear').click();

            // console.log("INPUT NAME: ", this.$refs["inputname"]);


            // this.$refs.inputname.value = "";





            // if (this.currGruppo) {
            //     this.currGruppo = dipendente;
            //     this.nomeCorrente = dipendente.nome;

            //     this.nomeCompletoCorrente = this.currGruppo.nome;

            //     console.log("this.currGruppo", this.currGruppo);

            // } else {
            //     this.currGruppo = {};

            // }

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "",
                    icon: "mdi-email-send-outline",
                    link: "/invia",
                    id: "btn_invia_msg_demo",
                    disabled: false,
                    image: "https://app.emtool.eu/public/_lib/img/save-alertmsg.png",
                    title: "Salva",
                    width: 30
                });

                // pulsantis.push({
                //     text: "",
                //     icon: "mdi-arrow-left",
                //     link: "/back",
                //     id: "btn_back_demo",
                //     disabled: false,
                //     image: "https://app.emtool.eu/public/_lib/img/back-alertmsg.png",
                //     title: "Indietro",
                //     width: 35

                // });

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

            setTimeout(() => {

                this.formatDropify();

            }, 100);

            this.changeDip();
            setTimeout(() => {
                //  this.syncMonth();
            }, 100);



        },

        manageClickDipendenti: function (dipendente, isGroup) {

            console.log("MANAGE_CLICK_DIPENDENTI");

            console.log(isGroup);
            this.sheetDipendenti = false;
            this.sheetGruppi = false;
            this.sheetAziende = false;
            this.sheetEmpty = false;
            this.currDipendente = dipendente.resourceid;
            this.dialogMsgSend = true;

            if (this.currGruppo) {
                this.currGruppo = dipendente;
                this.nomeCorrente = dipendente.nome;

                this.nomeCompletoCorrente = this.currGruppo.nome;

                console.log("this.currGruppo", this.currGruppo);

            } else {
                this.currGruppo = {};

            }

            setTimeout(() => {

                var pulsantis = [];

                pulsantis.push({
                    text: "",
                    icon: "mdi-arrow-left",
                    link: "/back",
                    id: "btn_back",
                    disabled: false,
                });

                pulsantis.push({
                    text: "",
                    icon: "mdi-email-send-outline",
                    link: "/invia",
                    id: "btn_invia_msg",
                    disabled: false,
                });

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

            setTimeout(() => {

                this.formatDropify();

            }, 100);

            this.changeDip();
            setTimeout(() => {
                //  this.syncMonth();
            }, 100);

        },

        formatDropify: function () {

            var oggDrop = $('.dropify').dropify({
                messages: {
                    default: 'Drag',
                    replace: /* s */ '',
                    remove: 'Rimuovi',
                    error: 'File troppo grande'
                }
            });

            $(".dropify-clear").html("<img src='https://app.smart-book.it/_lib/img/icons8-cestino-64.png' />")
            $(".dropify-render").append("<img>");

            //console.log(oggDrop);

            oggDrop.on('dropify.afterClear', function (event, element) {

                console.log(event, element);

            });

        },

        syncArrayDipendenti: async function () {

            console.log(this.currDipendente);

            this.currDipendente = "";
            /*             this.$root.$children[0].curDipTop = "";

                        this.$root.$children[0].curDipOreTop = "";
                        this.$root.$children[0].curDipOreTopLabel = ""; */

            //aziende
            var arrAziende = [];
            var arrDipendenti = [];
            var aziende = this.globalArrAziende; // Object.entries( this.monthArray.aziende  ) ;
            console.log("Elenco aziende:", this.aziende);
            for (var az of aziende) {
                var currArAzienda = az[1].fld_name;
                console.log("az", az);
                arrAziende.push(az[1].fld_name);
                var dipArray = az[1].dipendenti;
                for (var dip of dipArray) {
                    if (currArAzienda == this.currAzienda) {
                        arrDipendenti.push(dip);
                    }
                }
            }
            this.dipendenti = arrDipendenti;

        },

        manageClickAziende: async function (azienda) {

            this.currAzienda = azienda;
            this.buttonsNewMessage();
            await this.syncArrayDipendenti();

            console.log("CurAzienda", this.currAzienda);
            console.log(this.dipendenti);

            setTimeout(() => {

                this.sheetEmpty = true;

                //    this.sheetDipendenti = true;
            }, 300);

        },

        openMsgDialog: function (item) {

            console.log("ITEM: ", item);

            // item.read = "1";

            this.currItem = item;
            this.dialogMsg = true;
            //this.editedItem = Object.assign({}, this.defaultItem)
            this.defaultItem.title = item.title;
            this.defaultItem.body = item.desc;
            this.defaultItem.read = item.read;
            this.defaultItem.id = item.id;

            this.defaultItem.accepted = item.read;

            //  this.updateMessage(item.id);

        },

        closeSendMsg() {
            this.dialogMsgSend = false;
            this.setupButtons();

            setTimeout(() => {
                window.table.ajax.reload();

            }, 100);

        },


        closeSendMsgDemo() {
            this.dialogMsgSendDemo = false;
            this.setupButtons();

            window.table.ajax.reload();
            window.table.ajax.reload();


        },



        close() {
            this.dialogMsg = false;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },

        getDataFromApi() {
            this.loading = true
            this.realApiCall().then(data => {
                this.desserts = data.items
                this.totalDesserts = data.total
                this.loading = false
            })
        },

        realApiCall: async function () {
            let items = await this.getMessages();
            //console.log ( "ciao",1 );

            return new Promise((resolve, reject) => {
                //console.log ( "ciao",2 );
                const {
                    sortBy,
                    sortDesc,
                    page,
                    itemsPerPage
                } = this.options
                console.log(reject);

                //const total = items.length

                const total = this.totalDesserts;
                //   alert(total);

                if (sortBy.length === 1 && sortDesc.length === 1) {
                    items = items.sort((a, b) => {
                        const sortA = a[sortBy[0]]
                        const sortB = b[sortBy[0]]

                        if (sortDesc[0]) {
                            if (sortA < sortB) return 1
                            if (sortA > sortB) return -1
                            return 0
                        } else {
                            if (sortA < sortB) return -1
                            if (sortA > sortB) return 1
                            return 0
                        }
                    })
                }
                console.log(itemsPerPage, page);
                /* if (itemsPerPage > 0) {
                    items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
                } */

                setTimeout(() => {
                    resolve({
                        items,
                        total,
                    })
                }, 10)
            })
        },

        getMessages: async function () {

            var response;

            var token = window.$cookies.get("token");

            console.log("Options :", this.options);
            var itemsPerPage = this.options.itemsPerPage;

            var start = (this.options.itemsPerPage * this.options.page) - this.options.itemsPerPage;
            var arrMsg = [];
            try {
                response = await this.$api.getMessages(
                    token,
                    start,
                    itemsPerPage
                );

                this.totalDesserts = response.data.Messages.msg_tot;
                this.$root.$children[0].totalMsg = response.data.Messages.msg_tot_nr;

                var msg = response.data.Messages.pagine[0].messaggi;
                console.log("msg", msg);
                /*  for ( let [key] in msg)
                 {
                     //console.log ( "messaggio", msg[key] );
                     arrMsg.push ( msg[key] ) ;

                 } */

                for (let [key, value] of Object.entries(msg)) {
                    console.log("Messaggio", key, value);
                    arrMsg.push(value);
                }

            } catch (error) {
                // this.showSpinner = false;
                console.log(error);
            }
            console.log(response);
            return arrMsg;


        },

        setupButtons: async function () {
            setTimeout(() => {

                var pulsantis = [];

                var a = window.$cookies.get("a");
                var b = window.$cookies.get("b");

                if (!this.$root.$children[0].demo_version) {

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                        pulsantis.push({
                            text: "",
                            icon: "mdi-message-plus",
                            link: "/dash",
                            id: "btn_aziende",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                            title: "Aggiungi",
                            width: 30

                        });
                    }

                }
                else {


                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                        pulsantis.push({
                            text: "",
                            icon: "mdi-message-plus",
                            link: "/dash",
                            id: "btn_aziende_demo",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                            title: "Aggiungi",
                            width: 30

                        });
                    }




                }

                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);
        },


        hideShowButtonsSelRow: function () {

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");
            var user = window.$cookies.get("username");

            console.log("USERNAME: ", user);

            console.log("CUR ITEM ROW: ", this.currItemRow);


            window.numEventSelRow = 0;

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });




            console.log("TOT ROW SEL: ", tot);

            setTimeout(() => {

                var pulsantis = [];

                if (!this.$root.$children[0].demo_version) {

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                        if (tot == 0) {

                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });


                        }


                        if (tot > 0) {


                            pulsantis.push({
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/delete-alertmsg.png",
                                link: "/info",
                                id: "btn_delete_row",
                                disabled: false,
                                title: "Elimina",
                                width: 30

                            });



                        }


                    }


                    if (tot == 1) {


                        //$(v_row).addClass("active-row");

                        var v_row = $("table").find(".active-row");

                        var item = window.table.row(v_row).data();

                        console.log("ITEM ROW: ", item);

                        this.currItemRow = item;

                        console.log("IS SISGNATURE: ", this.currItemRow.signature);


                        console.log("TOT: ", tot);


                        pulsantis.push({
                            text: "",
                            icon: "mdi-eye",
                            link: "/dash",
                            id: "btn_view",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/view-alertmsg.png",
                            title: "Vedi",
                            width: 30

                        });




                    }



                }
                else {

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {


                        if (tot == 0) {

                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende_demo",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });


                        }


                        if (tot > 0) {


                            pulsantis.push({
                                text: "Delete",
                                icon: "mdi-home-circle",
                                image: "https://app.emtool.eu/public/_lib/img/delete-alertmsg.png",
                                link: "/info",
                                id: "btn_delete_row",
                                disabled: false,
                                title: "Elimina",
                                width: 30

                            });



                        }


                    }


                    if (tot == 1) {

                        var v_row2 = $("table").find(".active-row");

                        var item2 = window.table.row(v_row2).data();

                        console.log("ITEM ROW: ", item2);

                        this.currItemRow = item2;

                        console.log("TOT: ", tot);

                        console.log("IS SISGNATURE: ", this.currItemRow.signature);




                        pulsantis.push({
                            text: "",
                            icon: "mdi-eye",
                            link: "/dash",
                            id: "btn_view",
                            disabled: false,
                            image: "https://app.emtool.eu/public/_lib/img/view-alertmsg.png",
                            title: "Vedi",
                            width: 30

                        });


                    }


                }



                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },



        hideShowButtons: async function (v_enable) {

            var a = window.$cookies.get("a");
            var b = window.$cookies.get("b");
            var user = window.$cookies.get("username");

            console.log("USERNAME: ", user);

            window.numEventSelRow = 0;

            var tot = 0;

            $(".val_status").each(function (index) {

                console.log(index);

                if ($(this).prop("checked")) {

                    tot = tot + 1;

                }

            });

            console.log("HIDE SHOW BTS TOT: ", tot);

            setTimeout(() => {

                var pulsantis = [];

                if (v_enable == false) {

                    if (!this.$root.$children[0].demo_version) {

                        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });

                        }

                    }
                    else {

                        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {


                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende_demo",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });




                        }

                    }

                    if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {

                        if (tot > 0) {


                            pulsantis.push({
                                text: "",
                                icon: "mdi-delete",
                                link: "/delete",
                                id: "btn_delete_row",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/usr__NM__new_icon_delete_32.png",
                                class: "inverted",
                                title: "Elimina"

                            });

                        }


                    }


                }

                if (v_enable == true) {


                    if (!this.$root.$children[0].demo_version) {

                        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });
                        }



                    }
                    else {

                        if (a == 'Y' || a == 'S' || b == 'Y' || b == 'S') {
                            pulsantis.push({
                                text: "",
                                icon: "mdi-message-plus",
                                link: "/dash",
                                id: "btn_aziende_demo",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/add-alertmsg.png",
                                title: "Aggiungi",
                                width: 30

                            });
                        }

                    }

                    pulsantis.push({
                        text: "",
                        icon: "mdi-eye",
                        link: "/dash",
                        id: "btn_view",
                        disabled: false,
                        image: "https://app.emtool.eu/public/_lib/img/view-alertmsg.png",
                        title: "Vedi",
                        width: 30

                    });

                    // if ($("tr.active-row").find(".att").length > 0) {

                    //     if (user != "marco_romanelli") {

                    //         if ((window.curMsgIsEnableSigned == 1) && (window.curMsgIsSigned == 0)) {

                    //             pulsantis.push({
                    //                 text: "",
                    //                 icon: "mdi-file-pdf-box",
                    //                 link: "/pdf",
                    //                 id: "btn_view_pdf",
                    //                 disabled: false,
                    //                 image: "https://app.emtool.eu/public/_lib/img/signature.png",
                    //                 class: "inverted",
                    //                 title: "Firma"
                    //             });



                    //         }



                    //     }


                    // }


                    if (a == 'Y' || a == 'S') {

                        if (tot > 0) {


                            pulsantis.push({
                                text: "",
                                icon: "mdi-delete",
                                link: "/delete",
                                id: "btn_delete_row",
                                disabled: false,
                                image: "https://app.emtool.eu/public/_lib/img/usr__NM__new_icon_delete_32.png",
                                class: "inverted",
                                title: "Elimina"

                            });

                        }


                    }

                }

                this.$root.$children[0].bnavbuttons = pulsantis;

            }, 100);

        },

        buttonsNewMessage: async function () {
            setTimeout(() => {
                var pulsantis = [
                    /* {
                                        text: "Dashboard",
                                        nsertcon: "mdi-home-circle",
                                        link: "/dash",
                                        id: "btn_exit",
                                        disabled: false,
                                    }, */
                ];
                //var a = window.$cookies.get("a");

                //if (a == 'Y' || a == 'S') {
                pulsantis.push({
                    text: "",
                    icon: "mdi-arrow-left",
                    link: "/back",
                    id: "btn_back",
                    disabled: false,
                });


                /*    pulsantis.push({
                       text: "",
                       icon: "mdi-domain",
                       link: "/aziende",
                       id: "btn_companies",
                       disabled: false,
                   }, ); */

                if (this.currAzienda) {
                    pulsantis.push({
                        text: "",
                        icon: "mdi-account",
                        link: "/dipendenti",
                        id: "btn_dipendenti",
                        disabled: false,
                    });

                    pulsantis.push({
                        text: "",
                        icon: "mdi-account-multiple",
                        link: "/gruppi",
                        id: "btn_gruppi",
                        disabled: false,
                    });
                }

                //}
                this.$root.$children[0].bnavbuttons = pulsantis;
            }, 100);
        },

        updateMessage: async function (id_msg) {

            //  var username = window.$cookies.get("username");
            var token = window.$cookies.get("token");

            this.$root.$children[0].totalMsg = this.$root.$children[0].totalMsg - 1;

            var response;

            // console.log(username, token_system);
            try {
                response = this.$api.updateStatusMsgApi(
                    token,
                    id_msg
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }
            console.log(response);
        },

        saveProfile: async function () {
            var username = window.$cookies.get("username");
            var token_system = window.$cookies.get("token_system");
            this.showSpinner = true;
            this.$root.$children[0].showProgress = true;

            var response;

            console.log(username, token_system);
            try {
                response = await apiprofile.setProfile(
                    username,
                    token_system,
                    this.profile
                );
            } catch (error) {
                this.showSpinner = false;
                console.log(error);
            }

            this.showSpinner = false;
            this.$root.$children[0].showProgress = false;

            try {
                console.log(response.data.Result);

                this.$swal({
                    icon: "success",
                    text: "Your data has been saved"
                });

                /*     this.profile = this.iban = response.data.Result;
       //console.log("HERE",response.data.Result.iban);
    this.iban = response.data.Result.iban ;
    this.email = response.data.Result.mail ;
    this.codicefiscale = response.data.Result.codice_fiscale ;
    this.cellulare = response.data.Result.tel_cell ;
    this.namesurname = response.data.Result.name + " " + response.data.Result.surname  ;
    this.initials = response.data.Result.name.charAt(0) + "" + response.data.Result.surname.charAt(0)  ; */

                //this.monthArray = response.data.Timesheet;
            } catch (error) {
                console.log(error);
            }

            //  this.data.days = response.data ;
        },

        syncProfile: async function () {
            /*  var token = window.$cookies.get("token");
            var system = window.$cookies.get("system"); */

            //  this.data.days = response.data ;
        },

        btn_add_tms_click() {
            console.log(1);
        },
        /*          onClick() {
      console.log('a phone number was clicked');
    }
,
 */
        reply(par) {
            console.log(par);
        },
        validate() {
            //this.$root.$children[0].showProgress = true;

            var res = this.$refs.form.validate();
            console.log(res);
            if (!res) {
                this.$swal({
                    icon: "error",
                    text: "Please check your data"
                });
            } else {
                console.log("Profile: ", this.profile);
                this.saveProfile();
            }
        },

        reset() {
            this.$refs.form.reset();
        },
        resetValidation() {
            this.$refs.form.resetValidation();
        },
    },
};
</script>

<style scoped>
.titlenewrow {
    justify-content: center;
    padding-top: 25px;
    padding-bottom: 30px;
    font-weight: bolder;
}

.v-sheet.v-card:not(.v-sheet--outlined) {
    box-shadow: none;
}
</style>

<style>
.cont-table-msg {
    font-size: 10px;
    padding-top: 4px;
    margin: 0 auto;
}

.cont-table-msg .spin {

    display: flex;
    position: absolute;
    z-index: 100;
    min-width: 100vw;
    min-height: 100vh;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
}

.dataTable tbody tr,
.dataTable tbody tr td {
    vertical-align: middle;
    padding: 6px !important;
}

.table-responsive {
    max-width: 100vw;
}

ion-items {
    min-width: 60px;

}

ion-items ion-datetime {
    margin-left: 25px;
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.colcontTable .col-sm-12 {
    padding:
        0px;
}

.divCont {
    max-width: 500px;
    text-align: center;
    margin: auto;
}

.cont_messaggi .col_n {}

.cont_messaggi .selaggiorna {
    max-width: 25px;
    min-height: 25px;
    margin-left: 0px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 3px;
}

.cont_messaggi .selstato {
    max-width: 25px;
    min-height: 25px;
    margin-left: 2px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 29px;
    background-repeat: no-repeat;
    background-image: url(/alertmsg/img/filtro-stato-alertmsg.png);
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-top: 1px;
}


.header_style {

    background-color: #fa0025;
    padding: 5px 5px 5px 5px;
    text-align: left;

}

.dropify-clear {
    border: 0px !important;
    margin-top: -7px !important;
    margin-right: -7px !important;
}

.pay {
    display: flex;
    justify-content: center;
    padding-top: 10px;
    color: #f77002;

}

.pay a {
    color: #f77002;

    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;

}

.pay a:hover {
    color: #d56e1a;
}

.plan {
    margin-top: 20px;
    font-size: 12px;
    display: flex;
    justify-content: center;

}

.policy {
    text-align: center;
    margin-top: 30px;
}

.version {
    font-size: 10px;
    margin-top: 25px;
    text-align: center;
}

.version span {
    font-size: 13px;
}

.titleproduct {
    font-size: 16px;
    font-size: 16px;
    color: #rgb(245, 207, 46);
    font-weight: 800;

    text-align: center;
    margin-top: 40px;

}

.Absolute-Center {

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    bottom: 0;
    height: 100px;
    left: 0;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    width: 300px;
}

.logo {
    text-align: center;
}

.dialogMsg {
    min-width: 100vw !important;
}

span.corrente {
    margin-left: 4px;
}

.imgread1 {
    visibility: hidden;
}

.imgread0 {
    visibility: visible;
    width: 22px;
}

.msgread1 {
    font-weight: normal;
}

.msgread0 {
    font-weight: bold;
}

.v-application .elevation-1 {
    box-shadow: none !important;
    ;
}

span.hour {
    font-size: 10px;
}

.msgDest {
    font-size: 10px;
}

.msgDesc {
    min-width: 66px;
    font-size: 10px;
}

.dropify-clear img {
    /* display: none; */
    width: 20px !important;
}


.files label {
    margin-left: 0px !important;
}

.cont_messaggi #myTable_wrapper {
    min-width: initial;
    min-width: 930px;
    max-width: 930px;
    width: 930px;
}

.cont_messaggi .nome_parente {
    min-width: 200px;
}

.cont_messaggi .inserito {
    min-width: 70px;
}

.cont_messaggi .osp_assoc {
    min-width: 70px;
    display: flex;
    justify-content: center;
}

.cont_messaggi .mail {
    min-width: 200px;
}

.cont_messaggi td:first-child {
    padding: 10px !important;
}

.cont_messaggi #myTable_filter {
    padding-top: 1px;
    padding-bottom: 1px;
    position: fixed;
    left: 0px;
    max-width: 100%;
    background-color: white;
    border-color: #fa0025;

}

.cont_messaggi #myTable {
    margin-right: 3px;
    max-width: 930px;
}

.cont_messaggi table.dataTable.dtr-inline.collapsed>tbody>tr>td.dtr-control,
.cont_messaggi table.dataTable.dtr-inline.collapsed>tbody>tr>th.dtr-control {
    padding-left: 30px !important;
}

.cont_messaggi th {
    padding-left: 5px !important;
}

.cont_messaggi ion-datetime.dateBook {
    margin-left: 16px;
}

.cont_messaggi .table-responsive {
    padding: 0px;
    min-width: 80vw;
    max-width: 96vw;
}

/* .cont_messaggi .font-row-grid-datetime-mobile {
    font-size: 10px !important;
    padding: 5px !important;
    width: 64px !important;
    padding-left: 0px !important;
} */

.cont_messaggi #myTable_filter {
    position: fixed;
    left: 0px;
    min-width: 100%;
    z-index: 3;
}

.cont_messaggi div.dataTables_wrapper div.dataTables_filter input {
    /* margin-top:5px!important */
}

.cl-date {
    font-size: 10px;
    color: gray;
}

.cont_messaggi #myTable_wrapper {
    padding-top: 0px;
}

.cont_messaggi .text_title {
    background-color: white;
    color: black;
    font-weight: bold;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 0px;
    font-size: 10px;
    min-width: 80px;
    text-align: left;
}

.cont_messaggi .cl_active {
    display: inline-flex;
    align-items: center;
    /* justify-content: flex-start!important; */
    /*  text-align: center; */
    min-width: 100%;

    justify-content: center;
}

.cont_messaggi .cont_buttons {
    display: flex;
    align-items: center;
    float: left;
    min-width: 100px;
    padding-left: 10px;
    justify-content: inherit;

}

.cl_vcard {
    max-height: 100vh;
    overflow-y: auto;
    padding-bottom: 150px;
}

.mainTitleInsMsg {
    padding: 15px !important;
    position: fixed !important;
    min-width: 100vw !important;
    z-index: 3 !important;
    top: 64px !important;
    border: 1px solid #fa0025 !important;
    background-color: white;
    color: black;
    font-weight: bold;
    padding: 4px;
    font-size: 10px;
    min-width: 100vw;
    z-index: 3;
    border-color: #fa0025 !important;
}

.fieldsMsgSendDemo {
    margin-top: 55px !important;
}

.fieldsDialogMsg {
    margin-top: 55px !important;
}

.cl_is_signatured {
    text-align: center;
}

.cl_signature_enabled {
    text-align: left;
    padding-left: 42px;
}

.cont_messaggi .cl_checkall {
    padding-left: 5px;
    padding-top: 2px;
}

.cont_messaggi .checkall {
    width: 13px;
    height: 13px;
}

.cont_messaggi .val_status {
    width: 10px !important;
}

.cont_messaggi .selricerca {
    max-width: 31px;
    min-height: 31px;
    margin-left: 9px;
    color: black !important;
    padding: 0px 0px;
    min-width: 14px !important;
    text-indent: -119px;
    background-size: 19px;
    background-repeat: no-repeat;
    z-index: 2;
    border: none !important;
    cursor: pointer;
    margin-right: 14px !important;
}


@media screen and (max-width: 768px) {

    .cont_messaggi .att {
        display: inline-block;
        padding-left: 11px;
    }


    .cont_messaggi .cl_checkall {
        padding-left: 24px;
        padding-top: 2px;
    }


    .cont_messaggi .checkall {
        width: 13px;
        height: 13px;
    }


    .cl_cmp_mitt {
        display: inline-flex;
        padding-left: 16px;
    }

    .cl_is_signatured {
        text-align: center;
        display: inline;
        padding-left: 20px;
    }

    .cl_signature_enabled {
        display: inline;
        padding-left: 6px;
    }

    .header_style {

        background-color: #fa0025;
        padding: 5px 5px 5px 5px;
        text-align: left;

    }

    .dropify-clear {
        border: 0px !important;
        margin-bottom: -26px !important;
    }

    .cont_messaggi .cl_active {
        display: contents;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
    }

    .cont_messaggi #myTable {
        max-width: 100vw;
        margin-right: 0px;
        min-width: 100vw;
        position: relative;
        width: 100vw !important;
    }

    .cont_messaggi #myTable_filter {
        top: 56px;
        position: fixed;

    }

    .cont_messaggi .table-responsive {
        padding: 0px;
        min-width: 80vw;
        max-width: 96vw;
        left: 0px;
        position: fixed;

    }

    .cont_messaggi #myTable_wrapper {
        min-width: initial;
        min-width: 100vw !important;
        max-width: 100vw !important;
        width: 100vw !important;
        overflow-x: hidden !important;
    }

    .cont_messaggi .col_n {
        margin-left: -3px;
    }

    .mainTitleInsMsg {
        padding: 15px !important;
        position: fixed !important;
        min-width: 100vw !important;
        z-index: 3 !important;
        top: 56px !important;
        border: 1px solid black !important;
        background-color: #fa0025;
        color: white;
        font-weight: bold;
        padding: 4px;
        font-size: 10px;
        min-width: 100vw;
        z-index: 3;
    }



}

.buttonsPdf {
    margin-bottom: 150px;
    padding-top: 10px;
}

.buttonsPdf button {
    margin-right: 6px;
    margin-left: 6px;


}

.contSignature {
    margin: 0 auto;
}

.contSignature canvas {

    max-width: 98vw;
    border: 1px solid hsla(0, 0%, 50.2%, 0.33);
    padding-bottom: 10px;
    padding-top: 10px;
}

.swal2-styled.swal2-cancel {
    border: 0 !important;
    border-radius: .25em !important;
    background: initial !important;
    background-color: #fff !important;
    color: #fff !important;
    font-size: 1em !important;
}

.swal2-styled.swal2-confirm {
    background-color: #fff !important;
    color: black !important;
    background: initial !important;
}
</style>
