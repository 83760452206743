import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APIOtp {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }


  async generateOTPAndSendMail(user, name_product, tipo_op, id)
  {

    let formData = new FormData();

    formData.append("username",  user);
    formData.append("name_product",  name_product);
    formData.append("tipo_op",  tipo_op);
    formData.append("v_id",  id);
    
    return axios.post(this.getUrlDomain("generateOtpAndSendMail") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }








}

export default new APIOtp({
  url: "https://services.ebadge.it/public/api/"
})
