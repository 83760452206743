import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APITrial {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + nome_metodo;


  }

  async verifyTrial(user, product_name)
  {


    let formData = new FormData();

    formData.append("username", user);
    formData.append("product_name", product_name);
   
    return axios.post(this.getUrlDomain("IsProductEnable") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }


}

export default new APITrial({
  url: "https://services.ebadge.it/public/api/"
})
