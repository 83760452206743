import axios from 'axios'

//import { isProduction, env } from '@/utils/env'

//var httpOk = null ;

class APISettings {

  getUrlDomain(nome_metodo)
  {

    let result = "";

    if (location.hostname == "localhost") {
        result = location.protocol + "//emtool.local/";
    }

    if (location.hostname != "localhost") {

        result = location.protocol + "//" + location.hostname + "/";

    }

    return result + "public/api/alertmsg/" + nome_metodo;


  }

  async saveSettings(settings, user)
  {


    let formData = new FormData();

    formData.append("v_user",  user);
   
    for ( var key in settings ) {
       formData.append(key, settings[key]);
    }

    return axios.post(this.getUrlDomain("AlertMsgSaveSettings") , formData ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

  async getSettings()
  {


    return axios.get(this.getUrlDomain("AlertMsgGetSettings") ,{ 'Content-Type': 'multipart/form-data' }   ) ;

  }

}

export default new APISettings({
  url: "https://services.ebadge.it/public/api/"
})
